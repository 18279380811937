import React from "react";
import logo from "../assets/images/bgmage.jpeg";
export default function Home() {
  const logoContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // Full viewport height
    textAlign: "center",
  };

  const logoImageStyle = {
    height: "400px", // Set height to 400px
    width: "400px", // Set width to 400px
  };

  const titleStyle = {
    marginTop: "20px", // Space between image and title
    fontSize: "16px",
    color: "#000",
    marginLeft: 100,
    marginRight: 100,
  };

  return (
    <div style={logoContainerStyle}>
      <img src={logo} alt="Logo" style={logoImageStyle} />
      <h5 style={titleStyle}>
        Hello! Our website is currently under construction to serve you better.
        In the meantime, please feel free to reach out to us directly via phone
        at 7905733781 to place your request or inquire about our services.
      </h5>
    </div>
  );
}
