import React, { useState } from "react";
import "./DeleteRequest.css";

const DeleteRequest = () => {
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (customerMobile.length !== 10) {
      alert("Mobile number must be 10 digits");
      return;
    }
    alert(`Delete request submitted for: ${customerEmail}, ${customerMobile}`);
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setCustomerMobile(value);
    }
  };

  return (
    <div className="container">
      <h1 className="heading">Delete Data Request</h1>
      <form className="form" onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter customer's email"
          value={customerEmail}
          onChange={(e) => setCustomerEmail(e.target.value)}
          required
          className="input"
        />
        <input
          type="tel"
          placeholder="Enter customer's mobile number"
          value={customerMobile}
          onChange={handleMobileChange}
          required
          className="input"
        />
        <button type="submit" className="button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default DeleteRequest;
